<template>
    <Layout header-class="header-transparent" >
        <!-- Start Slider area -->
        <div class="slider-area slider-style-2 variation-3 height-1080 bg_image dive1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="inner text-left">
                            <h1 class="title" data-aos="fade-up" data-aos-delay="100">
                                <!-- <span class="theme-gradient" style="font-weight: normal">全新数字运动之旅<br/></span>  -->
                                
                                <!-- <h1 class="title" style="font-weight: normal; font-size: 55px; padding-top: 10px;">即将启程</h1>  -->
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider area -->

        <!-- Start Slider Area -->
        <div class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
                    <div class="height-1080 bg-overlay bg_image sliderdiv">
                        <img class="sliderimg" :src="require(`@/assets/images/bg/${slider.image}.jpg`)" alt=""
                            :srcset="require(`@/assets/images/bg/${slider.imagesm}.jpg`) + ' 1300w,' + require(`@/assets/images/bg/${slider.imagelg}.jpg`) + ' 1920w'"/>
                        <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="order-4 order-lg-4 col-lg-4">
                                    <div class="inner text-start">
                                        <h1 class="title" style="font-size: 48px; font-weight: normal" v-html="slider.title"/>
                                        <p class="description" style="font-size: 24px; font-weight: normal" v-html="slider.description"/>
                                        <!-- <div class="button-group mt--30">
                                            <a class="btn-default"
                                               href="#">
                                                PURCHASE NOW
                                            </a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <!-- End Slider Area -->

        <!-- Start Slider Area -->
        <div class="slider-area slider-style-4 variation-4 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData1" :key="index" class="single-slide">
                    <div class="height-1080 bg-overlay bg_image sliderdiv">
                        <img class="sliderimg" :src="require(`@/assets/images/bg/${slider.image}.jpg`)" alt=""
                            :srcset="require(`@/assets/images/bg/${slider.imagesm}.jpg`) + ' 1300w,' + require(`@/assets/images/bg/${slider.imagelg}.jpg`) + ' 1920w'"/>
                        <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="order-4 order-lg-4 col-lg-4"></div>
                                <div class="order-4 order-lg-4 col-lg-4"></div>
                                <div class="order-4 order-lg-4 col-lg-4">
                                    <div class="inner text-start">
                                        <h1 class="title" style="font-size: 48px; font-weight: normal;" v-html="slider.title"/>
                                        <p class="description" style="font-size: 24px; font-weight: normal;" v-html="slider.description"/>
                                        <!-- <div class="button-group mt--30">
                                            <a class="btn-default"
                                               href="#">
                                                PURCHASE NOW
                                            </a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <!-- End Slider Area -->

        <!-- Start Slider area  -->
        <div class="slider-area slider-style-2 variation-4 height-1080 bg-overlay bg_image div3">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12">
                            <div class="inner text-start">
                                <h2 class="title" data-aos="fade-up" data-aos-delay="120" style="color: #000;">Dedicate Training Contents</h2>
                                <p class="description" data-aos="fade-up" data-aos-delay="200" style="color: #131313;">
                                    Roworld offers various courses to users at all levels, ranging from relaxing stretching and warm ups to intensified HIIT and endurance trainings. Our casting function also allows you to streaming other training material over Roworld’s screen. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Slider area  -->
        <div class="slider-area slider-style-2 variation-3 height-1080 bg-overlay bg_image div4">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12"></div>
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12"></div>
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12">
                            <div class="inner text-start">
                                <h2 class="title" data-aos="fade-up" data-aos-delay="120" style="color: #000;">Detailed Records</h2>
                                <p class="description" data-aos="fade-up" data-aos-delay="200" style="color: #131313;">
                                    Your work out status will be recorded every 8 milliseconds with Roworld’s frame synchronization technology. So that you can have a very detailed work out summary. External Bluetooth devices connection are also supported.
                                </p>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <!-- End Slider area  -->

        <!-- Start Slider area  -->
        <div class="slider-area slider-style-2 variation-4 height-1080 bg-overlay bg_image div5">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12">
                            <div class="inner text-start">
                                <h2 class="title" data-aos="fade-up" data-aos-delay="120" style="color: #FFF;">Innovative Double Scull Experience</h2>
                                <p class="description" data-aos="fade-up" data-aos-delay="200" style="color: #CFCFCF;">
                                    We are the very first rower to enable online double scull experience for our users. By recording your stroke length, Roworld can simulate double scull like rowing experience with clashing alert and indications.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Slider area  -->
        <div class="slider-area slider-style-2 variation-3 height-1080 bg-overlay bg_image div6">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12"></div>
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12"></div>
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12">
                            <div class="inner text-start">
                                <h2 class="title" data-aos="fade-up" data-aos-delay="120" style="color: #fff;">Endless Match Ups</h2>
                                <p class="description" data-aos="fade-up" data-aos-delay="200" style="color: #CFCFCF;">
                                    You can enable Roworld’s work out records match up function in terms to compete with others. Your opponent can be either Olympians World Champion or just your previous best record.
                                </p>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <!-- End Slider area  -->

        <!-- Start Slider area  -->
        <div class="slider-area slider-style-2 variation-4 height-1080 bg-overlay bg_image div7">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12">
                            <div class="inner text-start">
                                <h2 class="title" data-aos="fade-up" data-aos-delay="120" style="color: #fff;">Stay Tunned </h2>
                                <p class="description" data-aos="fade-up" data-aos-delay="200" style="color: #CFCFCF;">
                                    Roworld’s APP allows you to stay in connect with other rowers anytime at anywhere. You can not only check and review your own workout data, but also stay tuned from your friends. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Testimonial Area  -->
        <!-- <div class="rwt-testimonial-area rn-section-gap" style="background-color: #101010">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="专业的建议让我们越来越完整"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-12"
                         v-for="(testimonial, index) in testimonialData"
                         :key="index">
                        <Testimonial :testimonial="testimonial" :testimonial-style="2"/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Testimonial Area  -->      

        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-default height-1080-100 bg_image">
            <video-background 
                :src="require(`@/assets/images/15s.mp4`)"
                :poster="require(`@/assets/images/shipinyujiazai.png`)"
                style="max-height: 100vh; height: 100vh;"
                overlay="linear-gradient(rgba(0, 0, 0, 0.5),transparent)">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner text-center">
                                <h1 class="title display-one" data-aos="fade-up" data-aos-delay="100" style="font-weight: normal">
                                    Rowrold is an innovative connected fitness product provider. 
                                </h1>
                                <div class="button-group" data-aos="fade-up" data-aos-delay="200">
                                    <router-link class="btn-default btn-medium btn-border btn-icon" to="/about" style="border-radius: 100px;">
                                        Learn More
                                        <Icon name="arrow-right" size="20" icon-class="icon"/>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </video-background>
        </div>
        <!-- End Slider Area -->
    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import Separator from '../../components/elements/separator/Separator'
    import VueSlickCarousel from 'vue-slick-carousel'
    import Testimonial from '../../components/elements/testimonial/Testimonial'
    import VideoBackground from 'vue-responsive-video-background-player'

    export default {
        name: 'RoworldEngilsh',
        components: {
            Testimonial,
            Separator,
            SectionTitle,
            Layout,
            VueSlickCarousel,
            VideoBackground
        },

        data() {
            return {
                sliderSetting: {
                    dots: true,
                    infinite: true,
                    autoplay: true,
                    fade: false,
                    draggable: true,
                    autoplaySpeed: 12000,
                    speed: 1500,
                    initialSlide: 0,
                    pauseOnHover: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipeToSlide: true,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 993,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 481,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                },
                sliderData: [
                    {
                        image: 'slider-image-1',
                        imagesm: 'slider-image-sm-1',
                        imagelg: 'slider-image-1',
                        title: 'Masterpiece Design',
                        description: 'Inspired by the concept of fluid water, Roworld’s design language is simple and smooth. It fits all environments no matter a cozy home or a hardcore gym. '
                    },
                    {
                        image: 'slider-image-2',
                        imagesm: 'slider-image-sm-2',
                        imagelg: 'slider-image-2',
                        title: 'Ambient Lights System',
                        description: 'The innovative ambient lights system can reflect users motion status and offer several color choices. '
                    },
                    {
                        image: 'slider-image-3',
                        imagesm: 'slider-image-sm-3',
                        imagelg: 'slider-image-3',
                        title: 'Foldable Option',
                        description: 'Roworld can be detached into 2 pieces by pressing the locking button, in this way you can pack or transport the rower more easily. '
                    },
                    {
                        image: 'slider-image-4',
                        imagesm: 'slider-image-sm-4',
                        imagelg: 'slider-image-4',
                        title: 'Smooth Slide',
                        description: 'The quiet and endurable slide design makes Roworld an indoor friendly rower to the other family members.'
                    }
                ],
                sliderData1: [
                    {
                        image: 'slider-image-10',
                        imagesm: 'slider-image-sm-10',
                        imagelg: 'slider-image-10',
                        title: '24” FHD Touchscreen',
                        description: 'Your fun rowing work out experiences are guaranteed by our 24” FHD touchscreen and a pair of 20 watts speaker system with more data details can be read easily in an organized way.'
                    },
                    {
                        image: 'slider-image-11',
                        imagesm: 'slider-image-sm-11',
                        imagelg: 'slider-image-11',
                        title: 'Beyond Rowing',
                        description: 'You can just turn the screen around a little to enjoy more exercises contents beyond rowing, no matter Yoga, On the mat training or whatever. This function can also service well for small group training purpose.'
                    },
                    {
                        image: 'slider-image-12',
                        imagesm: 'slider-image-sm-12',
                        imagelg: 'slider-image-12',
                        title: 'Speaker System',
                        description: 'Your fun rowing work out experiences are guaranteed by our 24” FHD touchscreen and a pair of 20 watts speaker system with more data details can be read easily in an organized way.'
                    }
                ],
                testimonialData: [
                    {
                        image: 'testimonial-01',
                        name: '张志远',
                        designation: '赛艇世界冠军',
                        description: 'ROWORLD划船机的诞生，为我们日常的训练提供了新的方式，解决的我们在日常训练中的一大痛点，现在已经普及到了各个省队。比市面上任何的一块划船机都是要体验好的。',
                    },
                    {
                        image: 'testimonial-02',
                        name: '杨超越',
                        designation: '中国赛艇运动荣誉舵手',
                        description: '健身教练强烈安利的划船机，收到之后果然不负所望，首先颜值超高，跟整个家具都很搭很高大有档次而且还可以折叠，不用等时候可以收起来，完全不占地方。',
                    },
                    {
                        image: 'testimonial-03',
                        name: '王石',
                        designation: '亚洲赛艇联合会荣誉主席',
                        description: '王石说，“如今我大力推广赛艇运动，是希望用体育交流连接世界。赛艇是一项讲究集体协作的运动，从船头的领桨手，到中间位置担任动力引擎的选手，以及船尾的平衡手，每个位置上的选手都有着明确的分工，大家都在舵手的带领下共同朝着同一个目标前进。”',
                    },
                ]
            }
        }
    }
</script>

<style>
        @media only screen and (max-width: 991px) {
          .dive1{
            background-image: url(../../assets/images/bg/bg-lg-eng-01.jpg);
          }
          .div3{
            background-image: url(../../assets/images/bg/bg-sm-03.jpg);
          }
          .div4{
            background-image: url(../../assets/images/bg/bg-sm-04.jpg);
          }
          .div5{
            background-image: url(../../assets/images/bg/bg-sm-05.jpg);
          }
          .div6{
            background-image: url(../../assets/images/bg/bg-sm-06.jpg);
          }
          .div7{
            background-image: url(../../assets/images/bg/bg-sm-07.jpg);
          }
          .divf{
            background-image: url(../../assets/images/bg/bg-sm-ft.jpg);
          }
        }
        @media only screen and (min-width: 991px) {
          .dive1{
            background-image: url(../../assets/images/bg/bg-lg-eng-01.jpg);
            background-attachment:fixed;
          }
          .div3{
            background-image: url(../../assets/images/bg/bg-lg-03.jpg);
          }
          .div4{
            background-image: url(../../assets/images/bg/bg-lg-04.jpg);
          }
          .div5{
            background-image: url(../../assets/images/bg/bg-lg-05.jpg);
          }
          .div6{
            background-image: url(../../assets/images/bg/bg-lg-06.jpg);
          }
          .div7{
            background-image: url(../../assets/images/bg/bg-lg-07.jpg);
          }
          .divf{
            background-image: url(../../assets/images/bg/bg-lg-ft.jpg);
          }
        }

        .sliderdiv {
            display: inline-block;
            overflow: hidden;
            position: relative;
            width: 100%;
        }

        .sliderimg {
            pointer-events: none;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
</style>