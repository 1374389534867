<template>
    <div>
        <!-- Start Footer Area  -->
        <footer v-if="data.showFooter" class="rn-footer footer-style-default variation-two">
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-6 col-6">
                            <div class="rn-footer-widget">
                                <h4 class="title">首页</h4>
                                <div class="inner">
                                    <ul class="footer-link link-hover">
                                        <li>
                                            <router-link to="/">ROWORLD</router-link>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-6">
                            <div class="rn-footer-widget">
                                <h4 class="title">关于</h4>
                                <div class="inner">
                                    <ul class="footer-link link-hover">
                                        <li>
                                            <router-link to="/about">关于我们</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/about">加入我们</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/about">联系方式</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/about">业务合作</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-6">
                            <div class="rn-footer-widget">
                                <h4 class="title">服务</h4>
                                <div class="inner">
                                    <ul class="footer-link link-hover">
                                        <li>
                                            <router-link to="/userAgreement">用户协议</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/privacyPolicy">隐私协议</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/paidMemberUserAgreement">会员服务协议</router-link>
                                        </li>
                                        <li>
                                            <router-link to="/automaticRenewal">自动续费条款</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-6">
                            
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-6">
                            <div class="rn-footer-widget">
                                <h4 class="title">ROWORLD | 用划船连接世界</h4>
                                <div class="inner">
                                    <h6 class="subtitle">北京市 东城区 南新仓商务大厦 东四十条甲22号1号楼B619B室<br/> 服务时间：工作日9:00-22:00</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />

                    <!-- Start Copy Right Area  -->
                    <div class="copyright-area copyright-style-one">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-8 col-sm-12 col-12">
                                    <div class="copyright-left text-center text-lg-start">
                                        <p class="copyright-text">Copyright © {{ new Date().getFullYear() }} 北京健入家境体育科技有限公司 版权所有</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-4 col-sm-12 col-12">
                                    <div class="copyright-right text-center text-lg-end">
                                        <a href="http://beian.miit.gov.cn/" class="copyright-text" target="_blank">京ICP备2021012265号-2</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102006522" class="copyright-text"><img src="../../../assets/images/beiantubiao.png"/>京公网安备 11010102006522号</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Copy Right Area  -->

                </div>
            </div>
            
        </footer>

        <ScrollTop/>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import ScrollTop from './ScrollTop'
    import Logo from '../../elements/logo/Logo'

    export default {
        name: 'Footer',
        components: {Logo, ScrollTop, Icon},
        props: {
            data: {
                default: null,
            }
        },
        methods: {
            itemMouseover: function() {
                document.getElementById('big').style.display='block';
            },
        
            itemMouseout: function() {
                document.getElementById('big').style.display='none';
            }
        }
    }
</script>

<style>
    @import "../../../assets/fonts/iconfont.css";
    #big {
            width: 210px;
            height: 210px;
            position: absolute;
            left: -90px;
            /* left: 50px; */
            top: 50%;
            z-index: 99;
            transform: translateY(-70%);
            display: none;
        }
</style>