<template>
    <ul class="mainmenu">
        <li><router-link to="/">ROWORLD</router-link></li>
        <li><router-link to="/about">关于我们</router-link></li>
        <li><router-link to="/aboutRowing">关于赛艇</router-link></li>
        <li>
            <div>
                <router-link to="/">中</router-link>
                <span> | </span>
                <router-link to="/roworld-english">Eng</router-link>
            </div>
        </li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon}
    }
</script>