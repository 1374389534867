<template>
    <Layout header-class="header-transparent" >
        <!-- Start Slider area -->
        <div class="slider-area slider-style-2 variation-3 height-1080 bg_image div1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="inner text-left">
                            <h1 class="title" data-aos="fade-up" data-aos-delay="100">
                                <!-- <span class="theme-gradient" style="font-weight: normal">全新数字运动之旅<br/></span>  -->
                                
                                <!-- <h1 class="title" style="font-weight: normal; font-size: 55px; padding-top: 10px;">即将启程</h1>  -->
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider area -->

        <!-- Start Slider Area -->
        <div class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
                    <div class="height-1080 bg-overlay bg_image sliderdiv">
                        <img class="sliderimg" :src="require(`@/assets/images/bg/${slider.image}.jpg`)" alt=""
                            :srcset="require(`@/assets/images/bg/${slider.imagesm}.jpg`) + ' 1300w,' + require(`@/assets/images/bg/${slider.imagelg}.jpg`) + ' 1920w'"/>
                        <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="order-4 order-lg-4 col-lg-4">
                                    <div class="inner text-start">
                                        <h1 class="title" style="font-size: 48px; font-weight: normal" v-html="slider.title"/>
                                        <p class="description" style="font-size: 24px; font-weight: normal" v-html="slider.description"/>
                                        <!-- <div class="button-group mt--30">
                                            <a class="btn-default"
                                               href="#">
                                                PURCHASE NOW
                                            </a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <!-- End Slider Area -->

        <!-- Start Slider Area -->
        <div class="slider-area slider-style-4 variation-4 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData1" :key="index" class="single-slide">
                    <div class="height-1080 bg-overlay bg_image sliderdiv">
                        <img class="sliderimg" :src="require(`@/assets/images/bg/${slider.image}.jpg`)" alt=""
                            :srcset="require(`@/assets/images/bg/${slider.imagesm}.jpg`) + ' 1300w,' + require(`@/assets/images/bg/${slider.imagelg}.jpg`) + ' 1920w'"/>
                        <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="order-4 order-lg-4 col-lg-4"></div>
                                <div class="order-4 order-lg-4 col-lg-4"></div>
                                <div class="order-4 order-lg-4 col-lg-4">
                                    <div class="inner text-start">
                                        <h1 class="title" style="font-size: 48px; font-weight: normal;" v-html="slider.title"/>
                                        <p class="description" style="font-size: 24px; font-weight: normal;" v-html="slider.description"/>
                                        <!-- <div class="button-group mt--30">
                                            <a class="btn-default"
                                               href="#">
                                                PURCHASE NOW
                                            </a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <!-- End Slider Area -->

        <!-- Start Slider area  -->
        <div class="slider-area slider-style-2 variation-4 height-1080 bg-overlay bg_image div3">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12">
                            <div class="inner text-start">
                                <h2 class="title" data-aos="fade-up" data-aos-delay="120" style="color: #000;">丰富的专业内容</h2>
                                <p class="description" data-aos="fade-up" data-aos-delay="200" style="color: #131313;">
                                    划船星球为广大运动爱好者设置了多种训练模式，无论“初级新手训练”还是“20分钟HIIT高强度燃脂划行”，总有一款适合你。我们还提供安全，科学的变阻训练课程，以进一步激发你的身体潜能。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Slider area  -->
        <div class="slider-area slider-style-2 variation-3 height-1080 bg-overlay bg_image div4">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12"></div>
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12"></div>
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12">
                            <div class="inner text-start">
                                <h2 class="title" data-aos="fade-up" data-aos-delay="120" style="color: #000;">实时详尽数据</h2>
                                <p class="description" data-aos="fade-up" data-aos-delay="200" style="color: #131313;">
                                    划船星球的“帧同步”技术能完整的记录训练过程中的每一个细节，并通过数据图表详尽呈现完整运动状态。ROWORLD支持好友间的数据分享，和小伙伴一起见证你每天的进步和挥洒的汗水。
                                </p>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <!-- End Slider area  -->

        <!-- Start Slider area  -->
        <div class="slider-area slider-style-2 variation-4 height-1080 bg-overlay bg_image div5">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12">
                            <div class="inner text-start">
                                <h2 class="title" data-aos="fade-up" data-aos-delay="120" style="color: #FFF;">首创双人艇模拟</h2>
                                <p class="description" data-aos="fade-up" data-aos-delay="200" style="color: #CFCFCF;">
                                    ROWORLD是全球首款支持多人远程线上双人艇训练的划船机。只要你和小伙伴同时登录不同的ROWORLD设备，就可以相约一起感受真实水面上双人艇的划行及打桨体验。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Slider area  -->
        <div class="slider-area slider-style-2 variation-3 height-1080 bg-overlay bg_image div6">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12"></div>
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12"></div>
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12">
                            <div class="inner text-start">
                                <h2 class="title" data-aos="fade-up" data-aos-delay="120" style="color: #fff;">一战到底</h2>
                                <p class="description" data-aos="fade-up" data-aos-delay="200" style="color: #CFCFCF;">
                                    获胜是对努力付出最好的褒奖，ROWORLD“挑战”模式下，你可以向好友，陌生人甚至专业运动员取得的运动成绩发起挑战，友好切磋，超越自己，共同进步。
                                </p>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <!-- End Slider area  -->

        <!-- Start Slider area  -->
        <div class="slider-area slider-style-2 variation-4 height-1080 bg-overlay bg_image div7">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-12">
                            <div class="inner text-start">
                                <h2 class="title" data-aos="fade-up" data-aos-delay="120" style="color: #fff;">广交“艇友”</h2>
                                <p class="description" data-aos="fade-up" data-aos-delay="200" style="color: #CFCFCF;">
                                    通过划船星球APP不仅可以随时随地查看自己的运动记录，还能实时获取好友最新动态，结识ROWORLD中的其他玩家，享受赛艇运动带来的无尽乐趣。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Testimonial Area  -->
        <!-- <div class="rwt-testimonial-area rn-section-gap" style="background-color: #101010">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="专业的建议让我们越来越完整"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-12"
                         v-for="(testimonial, index) in testimonialData"
                         :key="index">
                        <Testimonial :testimonial="testimonial" :testimonial-style="2"/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Testimonial Area  -->      

        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-default height-1080-100 bg_image">
            <video-background 
                :src="require(`@/assets/images/15s.mp4`)"
                :poster="require(`@/assets/images/shipinyujiazai.png`)"
                style="max-height: 100vh; height: 100vh;"
                overlay="linear-gradient(rgba(0, 0, 0, 0.5),transparent)">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner text-center">
                                <h1 class="title display-one" data-aos="fade-up" data-aos-delay="100" style="font-weight: normal">
                                    我们是一家智能健身硬件品牌<br>致力于为用户打造不同场景下的健身生活方式<br/>
                                </h1>
                                <div class="button-group" data-aos="fade-up" data-aos-delay="200">
                                    <router-link class="btn-default btn-medium btn-border btn-icon" to="/about" style="border-radius: 100px;">
                                        了解我们
                                        <Icon name="arrow-right" size="20" icon-class="icon"/>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </video-background>
        </div>
        <!-- End Slider Area -->

        <div class="height-650 bg-overlay bg_image divf">

        </div>
    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import Separator from '../../components/elements/separator/Separator'
    import VueSlickCarousel from 'vue-slick-carousel'
    import Testimonial from '../../components/elements/testimonial/Testimonial'
    import VideoBackground from 'vue-responsive-video-background-player'

    export default {
        name: 'Roworld',
        components: {
            Testimonial,
            Separator,
            SectionTitle,
            Layout,
            VueSlickCarousel,
            VideoBackground
        },

        data() {
            return {
                sliderSetting: {
                    dots: true,
                    infinite: true,
                    autoplay: true,
                    fade: false,
                    draggable: true,
                    autoplaySpeed: 8000,
                    speed: 1500,
                    initialSlide: 0,
                    pauseOnHover: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipeToSlide: true,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 993,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 481,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                },
                sliderData: [
                    {
                        image: 'slider-image-1',
                        imagesm: 'slider-image-sm-1',
                        imagelg: 'slider-image-1',
                        title: '大师杰作',
                        description: '设计灵感源自流水，整体线条简洁流畅，宜商宜家，彰显东方神韵和现代科技的交融之美。'
                    },
                    {
                        image: 'slider-image-2',
                        imagesm: 'slider-image-sm-2',
                        imagelg: 'slider-image-2',
                        title: '创新氛围灯',
                        description: 'ROWORLD创新的氛围灯系统可以感知识别用户锻炼节奏，营造运动氛围的同时提供多种颜色选择。'
                    },
                    {
                        image: 'slider-image-3',
                        imagesm: 'slider-image-sm-3',
                        imagelg: 'slider-image-3',
                        title: '人体工学设计',
                        description: '包胶手柄，PU坐凳，可调脚踏，移动滚轮，对每一处细节的吹毛求疵只为更安全，科学，高效的体验。'
                    },
                    {
                        image: 'slider-image-4',
                        imagesm: 'slider-image-sm-4',
                        imagelg: 'slider-image-4',
                        title: '安静不打扰',
                        description: '铝合金静音导轨配合进口座椅轴承，带来丝滑运动体验的同时大幅降低噪音，保障家人休息时不被打扰。'
                    }
                ],
                sliderData1: [
                    {
                        image: 'slider-image-10',
                        imagesm: 'slider-image-sm-10',
                        imagelg: 'slider-image-10',
                        title: '岂止屏大',
                        description: 'ROWORLD配备的24寸全高清触控屏幕，搭载RK3399主板，支持视频APP投屏，全屏数据监控，虚拟现实游戏竞赛等丰富内容。'
                    },
                    {
                        image: 'slider-image-11',
                        imagesm: 'slider-image-sm-11',
                        imagelg: 'slider-image-11',
                        title: '自由转换',
                        description: '大屏支持水平120°自由转向调节，实现拉伸，瑜伽，跳操，团课等功能只需轻转屏幕，精彩内容即刻呈现。'
                    },
                    {
                        image: 'slider-image-12',
                        imagesm: 'slider-image-sm-12',
                        imagelg: 'slider-image-12',
                        title: '非凡“响”受',
                        description: '内置20w高功率3D立体声音响系统，超凡聆听，感受纯真音质，响你所想，沉浸愉悦。'
                    }
                ],
                testimonialData: [
                    {
                        image: 'testimonial-01',
                        name: '张志远',
                        designation: '赛艇世界冠军',
                        description: 'ROWORLD划船机的诞生，为我们日常的训练提供了新的方式，解决的我们在日常训练中的一大痛点，现在已经普及到了各个省队。比市面上任何的一块划船机都是要体验好的。',
                    },
                    {
                        image: 'testimonial-02',
                        name: '杨超越',
                        designation: '中国赛艇运动荣誉舵手',
                        description: '健身教练强烈安利的划船机，收到之后果然不负所望，首先颜值超高，跟整个家具都很搭很高大有档次而且还可以折叠，不用等时候可以收起来，完全不占地方。',
                    },
                    {
                        image: 'testimonial-03',
                        name: '王石',
                        designation: '亚洲赛艇联合会荣誉主席',
                        description: '王石说，“如今我大力推广赛艇运动，是希望用体育交流连接世界。赛艇是一项讲究集体协作的运动，从船头的领桨手，到中间位置担任动力引擎的选手，以及船尾的平衡手，每个位置上的选手都有着明确的分工，大家都在舵手的带领下共同朝着同一个目标前进。”',
                    },
                ]
            }
        }
    }
</script>

<style>
        @media only screen and (max-width: 991px) {
          .div1{
            background-image: url(../../assets/images/bg/bg-sm-01.jpg);
          }
          .div3{
            background-image: url(../../assets/images/bg/bg-sm-03.jpg);
          }
          .div4{
            background-image: url(../../assets/images/bg/bg-sm-04.jpg);
          }
          .div5{
            background-image: url(../../assets/images/bg/bg-sm-05.jpg);
          }
          .div6{
            background-image: url(../../assets/images/bg/bg-sm-06.jpg);
          }
          .div7{
            background-image: url(../../assets/images/bg/bg-sm-07.jpg);
          }
          .divf{
            background-image: url(../../assets/images/bg/bg-sm-ft.jpg);
          }
        }
        @media only screen and (min-width: 991px) {
          .div1{
            background-image: url(../../assets/images/bg/bg-lg-01.jpg);
            background-attachment:fixed;
          }
          .div3{
            background-image: url(../../assets/images/bg/bg-lg-03.jpg);
          }
          .div4{
            background-image: url(../../assets/images/bg/bg-lg-04.jpg);
          }
          .div5{
            background-image: url(../../assets/images/bg/bg-lg-05.jpg);
          }
          .div6{
            background-image: url(../../assets/images/bg/bg-lg-06.jpg);
          }
          .div7{
            background-image: url(../../assets/images/bg/bg-lg-07.jpg);
          }
          .divf{
            background-image: url(../../assets/images/bg/bg-lg-ft.jpg);
          }
        }

        .sliderdiv {
            display: inline-block;
            overflow: hidden;
            position: relative;
            width: 100%;
        }

        .sliderimg {
            pointer-events: none;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
</style>