<template>
    <Layout>
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-4" style="background-color: #FFFFFF">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner">
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <span class="description text-start" style="font-weight: 400;">
                                【更新日期：2022年11月15日】<br>
                                【生效日期：2022年11月15日】

                            </span>
                            <span class="subtitle text-center">
                                划船星球用户协议
                            </span>
                            <span class="description text-start" style="font-weight: 400;">
                                尊敬的用户： 非常感谢您选择划船星球，为了让您更好地使用我们提供的产品和服务，请您务必认真阅读、充分理解《划船星球用户协议》（以下简称「用户协议」或「本协议」）的各条款内容，特别是免除或者限制责任的条款，并选择是否接受。特别提示：<br>
划船星球 APP及www. roworld.com （以下统称「产品」）是北京健入家境体育科技有限公司及其关联公司（以下称「划船星球」或我们）提供的互联网产品和服务。在您注册成为用户（使用划船星球 APP服务的用户，以下简称“用户”或“您”）之前，请认真阅读本协议，特别是免除或者限制责任的条款、法律适用和争议解决条款等以粗体划线重点标识的条款。如果您对本协议的内容有任何疑问，请向划船星球 APP客服咨询。
                            </span>
                            <br/>
                            <br/>
                            <p class="description text-start">
                                <strong>一、用户协议的确认及接受</strong>
                            </p>
                            <span class="description text-start">
                                1.1 本协议是用户（您）与划船星球之间关于使用划船星球提供的产品和服务所订立的协议，本协议条款的效力及于划船星球所提供的一切产品和服务，用户在享受任何单项服务时，应当受本协议的约束。<br>
1.2当您按照注册或登录页面提示填写信息、阅读并同意本协议且完成全部注册或登录流程后，即表示您已充分阅读、理解并接受本协议的全部内容，并与划船星球 APP就本协议达成一致，成为划船星球 APP用户。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册或登录。<br>
1.3 本协议项下的产品和服务是指由划船星球发布的包括但不限于网络媒体、互联网增值、互动娱乐、电子商务和广告等互联网产品和服务（形式包括但不限于在线视频、评论、回复、弹幕、图片、软件、技术代码等），划船星球有权根据需要不时地修订本协议或/及各类规则（包括但不限于指定新的条款和修改既有条款），划船星球将在相关页面上提示修改的内容；一旦服务条款发生变更和修改，我们将在APP首页弹框通知您，您可以选择同意条款变更或者退出APP，如果您选择同意，则视为您已阅读、理解并接受经过修订的协议和规则；如果您选择退出，则视为您不同意更新的条款，退出后您将无法使用划船星球的产品和服务。修订后的协议和规则一经公布，立即或在公告明确的特定时间自动生效。<br>
1.4 本软件提供的相关信息，仅供参考，并不保证这些信息适合于您。且此类信息无意替代或暗示任何专业的意见。请在启动训练项之前，咨询健康专家或其他专业人士该项目是否适合您的个人需要和健康状况。尤其是当此软件中的训练计划所提及的训练和练习是具有一定运动强度的项目并有可能造成受伤，以及此软件中的挑战他人功能及实时比赛功能可能并不适应您本人身体状况从而造成运动伤害时，您必须在参与应用中描述的任何训练、挑战以及比赛之前向具有相关医疗执照的医生或其他专业人士进行咨询。
                            </span>
                            <p class="description text-start">
                                <strong>二、个人信息保护及账号管理</strong>
                            </p>
                            <span class="description text-start">
                                2.1 为完整使用划船星球提供的产品和服务，您须注册划船星球提供的账户。用户（您）注册划船星球账户时，须对所提供信息的真实性、合法性、有效性及完整性负责，并及时维护和更新您的个人信息，以保证信息的真实、合法和有效。<br>
2.2 您在划船星球中的注册账号所有权及有关权益均归公司所有，您完成注册手续后仅享有该账号的使用权。您的账号仅限于您本人使用，未经划船星球书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该账号。如果划船星球发现或者有合理理由认为使用者并非账号初始注册人，划船星球有权暂时或终止向该注册账号提供服务，并有权注销该账号，而无需向该注册账号的用户承担法律责任。<br>
2.3 关于您的个人信息的采集、存储、使用以及其他涉及您个人信息隐私相关情况，请您阅读本协议关联协议《隐私政策》，《隐私政策》与本协议不可分割且具有同等法律效力。<br>
2.4 鉴于“划船星球”帐号的绑定注册方式，您同意划船星球在注册时将使用您提供的手机号码及/或自动提取您的手机号码及自动提取您的手机设备识别码等信息用于注册。您同意给予运营商授权，授权运营商有权自动提取您的手机号码进行认证并用于“划船星球”账号注册，您保证遵守运营商的相关服务条款，如运营商对您的手机号认证成功，则您的注册即完成。如您不同意对运营商的授权和/或服务条款或者是您的手机号认证失败，您可以手动修改运营商提取的手机号码，采取验证码方式进行注册登录。<br>
2.5 划船星球特别提醒您务必保管好您的手机号码及验证码，用户在注册账号时，须使用手机号码绑定注册，切勿将账号及密码/验证码透露给他人。您须自行负责对您账户和密码进行保密，且须对在您账户和密码下发生的所有活动（包括但不限于信息披露、信息发布、网上点击同意或提交各类规则协议和购买服务等）承担责任。划船星球不能也不会对因您未遵守本条款规定而发生的任何损失负责。若您发现任何人未经授权使用您的账号及密码，您须立即通知划船星球，您可以发送邮件至Service@roworld.com提出您的请求。<br>
2.6 尊重用户个人隐私是划船星球的一贯制度，划船星球将采取技术措施和其他必要措施，确保用户个人信息安全，防止在本服务中收集的用户个人信息泄露、毁损或丢失。在发生前述情形或者划船星球发现存在发生前述情形的可能时，划船星球将及时采取补救措施并告知用户，用户如发现存在前述情形亦需立即与划船星球联系。<br>
2.7 您可以对账户设置昵称，您设置的昵称不得侵犯或涉嫌侵犯他人的合法权益。用户承诺设置昵称时遵守以下规定：<br>
（1）不以党和国家领导人或其他社会名人的真实姓名、字号、艺名、笔名注册；如已有注册，划船星球有权要求用户修改；<br>
（2）不以国家机构或其他机构的名称注册；<br>
（3）不注册不文明、不健康名字，或包含歧视、侮辱、猥亵类词语的账号；<br>
（4）不注册易产生歧义、引起他人误解或其他不符合法律规定的账号。<br>
如您设置的昵称涉嫌侵犯他人合法权益，划船星球有权终止向您提供部分或全部软件服务。划船星球对此不承担任何责任，您将承担因此产生的任何直接或间接损失及不利后果。<br>
2.8 划船星球未经用户同意不向任何第三方公开、 透露用户个人隐私信息。但以下特定情形除外：<br>
(1) 划船星球根据法律法规规定或有权机关的指示提供用户的个人隐私信息；<br>
(2) 用户与划船星球及合作单位之间就用户个人隐私信息的使用公开达成约定，划船星球因此向合作单位公开用户个人隐私信息；<br>
(3) 任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露；<br>
(4) 用户个人信息已经经过处理无法识别特定个人且不能复原。<br>
2.9 用户同意划船星球可在以下事项中使用用户的个人隐私信息：<br>
(1) 划船星球向用户及时发送重要通知，如软件更新、本协议条款的变更；<br>
(2) 划船星球内部进行审计、数据分析和研究等，以改进划船星球的产品、服务和与用户之间的沟通；<br>
(3) 依本协议约定，划船星球管理、审查用户信息及进行处理措施；<br>
(4) 适用法律法规规定的其他事项。<br>
除上述事项外，如未取得用户事先同意，划船星球不会将用户个人隐私信息使用于任何其他用途。<br>
                            </span>
                            <p class="description text-start">
                                <strong>三、划船星球产品及服务介绍</strong>
                            </p>
                            <span class="description text-start">
                                3.1 划船星球为用户提供多样的实体及互联网产品和服务，包括但不限于划船机、电商等实体产品及训练课程、训练程序、划行游戏、虚拟物品等相关线上产品，用户可以通过划船星球产品使用、分享、购买划船星球提供的产品和服务。用户有权获得划船星球提供的产品和服务，但划船星球有权收取由此产生的服务或产品的费用。对于收费服务，划船星球会在用户使用之前给予用户明确的提示，只有用户根据提示确认其同意按照前述支付方式支付费用并完成了支付行为，用户才能使用该等收费服务。支付行为的完成以银行或第三方支付平台生成“支付已完成”的确认通知为准。<br>
3.2 划船星球将尽力提供畅通无阻的在线服务，如平台的网络接入服务受多种因素的影响出现连接受阻、受限或被终止，由此给您带来的损失、不便，我们将不负任何责任。<br>
3.3 划船星球的替换、修改和升级：<br>
(1) 为增进用户体验、完善服务内容，划船星球有权根据您对软件的使用状态和行为开发新的服务；<br>
(2) 为改进、增强和进一步开发服务，划船星球有权不时提供软件更新（包括软件升级替换、修改、功能强化、版本升级等形式）；<br>
(3) 用户理解并同意：划船星球有权不向您特别通知而对划船星球进行更新，或对划船星球部分功能进行改变或限制。<br>
(4) 划船星球新版本发布后，旧版本可能无法使用。划船星球不保证旧版划船星球及相应的服务继续可用，为充分、及时使用划船星球的服务，请您随时核对并下载最新版本。<br>
                            </span>
                            <p class="description text-start">
                                <strong>四、会员服务</strong>
                            </p>
                            <span class="description text-start">
                                4.1会员服务，指 划船星球会员享有的特殊服务，以下简称「会员」。包括会员有效期内无限次免费使用投屏功能、免费无限次观看训练课程、免费无限次使用训练程序、免费无限次使用挑战功能、免费无限次使用比赛和游戏功能，运动积攒虚拟币，使用虚拟币兑换划船星球相关虚拟及现实产品，专属标识，以及其他由 划船星球 明示的优惠措施。用户开通会员服务时，须按照划船星球 公布的收费标准支付相应的会员服务费用。基于业务的发展，划船星球有权调整收费标准，调整后的收费标准自公布之日起生效。<br>
4.2 用户知悉并确认，当开通会员服务后，若您中途取消服务或终止资格的，Keep 将不退还您已支付的会员服务费用。<br>
4.3 使用会员服务过程中，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，则您及您的监护人应当承担因此而导致的一切后果，划船星球有权向您的监护人追偿。<br>
4.4 您知悉并同意，划船星球有权通邮件、短信等方式，向您发送会员相关活动的信息。<br>
4.5 划船星球向会员提供亲情账号服务，由一个主账号与会员身份绑定，会员账号可以指定多个亲情账号。主会员账号与其他亲情账号享受的服务内容完全一致。但划船星球大屏客户端的亲情账号必须在主会员账号绑定的划船星球硬件上方可使用。您确认会员服务仅限您本人及您授权的个人使用，同时，您保证，您将合理使用会员服务，不会利用会员服务非法获利，如划船星球有合理理由怀疑您存在不当使用会员服务时，划船星球有权取消您的会员资格且不退还您支付的会员服务费用，因此产生的相关责任及损失均由您自行承担，给划船星球 造成损失的，划船星球 保留向您追偿的权利。<br>
4.6 划船星球保留在法律法规允许的范围内自行决定是否接受您的会员服务申请、调整会员服务内容、取消会员资格等权利。<br>
                            </span>
                            <p class="description text-start">
                                <strong>五、电商服务</strong>
                            </p>
                            <span class="description text-start">
                                为满足用户对健身周边产品的需求，划船星球可能向用户提供电商服务。 划船星球 销售的商品均为自营产品。<br>
5.1 商品信息<br>
商品价格、数量、性能介绍、是否有货等商品信息随时都有可能发生变动， 划船星球 不作特别通知，请用户至商品销售页面自行查看。我们会尽最大努力保证您所浏览的商品信息的准确性，但由于商品种类繁多且商品信息的数量较大，加之互联网技术因素等客观原因存在，显示的信息可能会有一定的滞后性或差错，对此情形请您知悉并理解。划船星球 欢迎纠错，并会视情况给予纠错者一定的奖励。<br>
5.2 订单<br>
5.2.1 在您下订单时，请仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、收货人、电话、收货地址等信息。收货人与您本人不一致的，收货人的行为和意思表示视为您的行为和意思表示，您应对收货人的行为及意思表示的法律后果承担连带责任。您提交订单即表示对订单中所确认的订购商品、收货地址等信息的准确性负责。如果因为您填写的收货人联系电话、地址等信息错误，导致我们（或我们委托的配送公司）将货物交付给非您本意的收货人的，由此造成的损失需由您自行承担。<br>
5.2.2 如果您通过 划船星球订购商品，划船星球 产品展示的商品和价格等信息仅仅作为要约邀请，您的订单将成为订购商品的要约。收到您的订单后，我们会及时为您发货，只有当我们向您发出发货确认的电子邮件或短信，通知您我们已将您订购的商品发出时，才构成我们对您的订单的接受，您与我们之间的订购合同才成立。如果您在一份订单里订购了多种商品，而我们只向您发出了其中部分商品的发货确认电子邮件或短信，那么我们和您之间的订购合同仅就该部分商品成立。<br>
由于市场变化及各种以合理商业努力难以控制的因素的影响，如您拟购买的商品，在任何购买环节发生缺货或无货，您有权取消订单。<br>
5.2.4 为回馈用户的支持，我们会不定期向用户发放一些优惠券或代金券，上述优惠券或代金券的发放数量及使用规则由 划船星球 自行决定。您有权按照优惠券或代金券的使用规则在本站购物时的使用，严禁转卖 划船星球 优惠券或代金券或利用划船星球账户进行其他经营性行为等。<br>
5.3 配送<br>
5.3.1 我们将会把商品（货物）送到您指定的收货地址，所有在本站上列出的以及客服告知的送达时间为参考时间，参考时间的计算是在综合考量库存状况、正常的处理过程和送货时间、送货地点的基础上估算得出的。参考时间可能与实际送达的时间有所出入，请您知悉并理解。<br>
5.3.2 因如下情形造成订单延迟或无法配送等，划船星球 不承担延迟配送的责任：<br>
（1）因用户提供的信息错误、地址不详细等原因导致的；<br>
（2）货物送达后无人签收，导致无法配送或延迟配送的；<br>
（3）情势变更因素导致的；<br>
（4）不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。<br>
                            </span>
                            <p class="description text-start">
                                <strong>六、内容规范</strong>
                            </p>
                            <span class="description text-start">
                                6.1 本条所述内容是指用户使用本服务过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于帐号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用帐号或本服务所产生的内容。<br>
6.2 用户不得利用“划船星球”帐号或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：<br>
(1) 反对宪法所确定的基本原则的；<br>
(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br>
(3) 损害国家荣誉和利益的；<br>
(4) 煽动民族仇恨、民族歧视，破坏民族团结的；<br>
(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；<br>
(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；<br>
(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br>
(8) 侮辱或者诽谤他人，侵害他人合法权益的；<br>
(9) 不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的；<br>
(10) 含有法律、行政法规禁止的其他内容的信息。<br>
6.3 用户不得利用“划船星球”帐号或本服务制作、上载、复制、发布、传播如下干扰“划船星球”正常运营，以及侵犯其他用户或第三方合法权益的内容：<br>
(1) 含有任何性或性暗示的；<br>
(2) 含有辱骂、恐吓、威胁内容的；<br>
(3) 含有骚扰、垃圾广告、恶意信息、诱骗信息的；<br>
(4) 涉及他人隐私、个人信息或资料的；<br>
(5) 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；<br>
(6) 含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。<br>
                            </span>
                            <p class="description text-start">
                                <strong>七、使用规则</strong>
                            </p>
                            <span class="description text-start">
                                7.1 用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表划船星球的观点、立场或政策，划船星球对此不承担任何责任。<br>
7.2 用户不得利用“划船星球”帐号或本服务进行如下行为：<br>
(1) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；<br>
(2) 强制、诱导其他用户关注、点击链接页面或分享信息的；<br>
(3) 虚构事实、隐瞒真相以误导、欺骗他人的；<br>
(4) 利用技术手段批量建立虚假帐号的；<br>
(5) 利用“划船星球”帐号或本服务从事任何违法犯罪活动的；<br>
(6) 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；<br>
(7) 其他违反法律法规规定、侵犯其他用户合法权益、干扰“划船星球”正常运营或划船星球未明示授权的行为。<br>
7.3 用户须对利用“划船星球”帐号或本服务传送信息的真实性、合法性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与划船星球无关。如因此给划船星球或第三方造成损害的，用户应当依法予以赔偿。<br>
7.4 划船星球提供的服务中可能包括广告，用户同意在使用过程中显示划船星球和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责，对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，划船星球不承担任何责任。<br>
7.5 除非划船星球书面许可，用户不得从事下列任一行为：<br>
(1) 删除软件及其副本上关于著作权的信息；<br>
(2) 对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码；<br>
(3) 对划船星球拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；<br>
(4) 对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经划船星球授权的第三方工具/服务接入软件和相关系统；<br>
(5) 通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；<br>
(6) 通过非划船星球开发、授权的第三方软件、插件、外挂、系统，登录或使用划船星球软件及服务，或制作、发布、传播非划船星球开发、授权的第三方软件、插件、外挂、系统。<br>
                            </span>
                            <p class="description text-start">
                                <strong>八、数据储存</strong>
                            </p>
                            <span class="description text-start">
                                除法律另有规定外，用户有责任自行备份存储在 划船星球 的数据，如用户的服务被终止， 划船星球 有权从服务器上永久地删除您在 划船星球 的全部相关数据，并不负有向您返还或以任何形式提供前述数据的义务。
                            </span>
                            <p class="description text-start">
                                <strong>九、风险承担</strong>
                            </p>
                            <span class="description text-start">
                                9.1 用户理解并同意，“划船星球”仅为用户提供运动服务、信息分享、传送及获取的平台，用户必须为自己注册帐号下的一切行为负责，包括用户所传送的任何内容以及由此产生的任何后果。用户应对“划船星球”及本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。划船星球无法且不会对因用户行为而导致的任何损失或损害承担责任。 如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向划船星球举报或投诉，举报或投诉邮箱为Service@roworld.com，划船星球将依本协议约定进行处理。<br>
9.2 用户理解并同意，因业务发展需要，划船星球保留单方面对本服务的全部或部分服务内容变更、暂停、终止或撤销的权利，用户需承担此风险。
                            </span>
                            <p class="description text-start">
                                <strong>十、知识产权声明</strong>
                            </p>
                            <span class="description text-start">
                                10.1 除本服务中涉及广告的知识产权由相应广告商享有外，划船星球在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归划船星球所有，但用户在使用本服务前对自己发布的内容已合法取得知识产权的除外。<br>
10.2 除另有特别声明外，划船星球提供本服务时所依托软件的著作权、专利权及其他知识产权均归划船星球所有。<br>
10.3 划船星球在本服务中所涉及的图形、文字或其组成，以及其他划船星球标志及产品、服务名称（以下统称“划船星球标识”），其著作权或商标权归划船星球所有。未经划船星球事先书面同意，用户不得将划船星球标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用、或其他有权处理划船星球标识的行为。<br>
10.4 上述及其他任何划船星球或相关广告商依法拥有的知识产权均受到法律保护，未经划船星球或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品。<br>
10.5 用户在使用划船星球服务时发表上传的文字、图片、视频、音频、软件以及表演等信息，此部分信息的知识产权归用户，责任由用户承担。但用户的发表、上传行为视为对划船星球的授权，用户理解并同意授予划船星球及其关联公司在全球范围内完全免费、不可撤销、独家、永久、可转授权和可再许可的权利，包括但不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及《著作权法》规定的由著作权人享有的其他著作财产权利及邻接权利。划船星球可自行选择是否使用以及使用方式，包括但不限于将前述信息在划船星球旗下的服务平台上使用与传播，将上述信息再次编辑后使用，以及由划船星球授权给合作方使用、编辑与传播等。<br>
                            </span>
                            <p class="description text-start">
                                <strong>十一、法律责任</strong>
                            </p>
                            <span class="description text-start">
                                11.1 如果划船星球发现或收到他人举报或投诉用户违反本协议约定的，划船星球有权不经通知随时对相关内容，包括但不限于用户资料、聊天记录进行审查、删除，并视情节轻重对违规帐号处以包括但不限于警告、帐号封禁 、设备封禁 、功能封禁 的处罚，且通知用户处理结果。<br>
11.2 用户理解并同意，划船星球有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。<br>
11.3 用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿划船星球与合作公司、关联公司，并使之免受损害。<br>
                            </span>
                            <p class="description text-start">
                                <strong>十二、不可抗力及其他免责事由</strong>
                            </p>
                            <span class="description text-start">
                                12.1 用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，划船星球将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，划船星球及合作单位在法律允许的范围内免责。<br>
12.2 本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。<br>
12.3 用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，划船星球不承担任何责任。<br>
12.4 用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，划船星球不承担任何责任。<br>
12.5 用户理解并确认，划船星球需要定期或不定期地对“划船星球”平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，划船星球无需为此承担任何责任，但划船星球应事先进行通告。<br>
12.6 划船星球依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成划船星球的义务或承诺，划船星球不能保证及时发现违法违规或违约行为或进行相应处理。<br>
12.7 用户理解并确认，对于划船星球向用户提供的下列产品或者服务的质量缺陷及其引发的任何损失，划船星球无需承担任何责任：<br>
(1) 划船星球向用户免费提供的服务；<br>
(2) 划船星球向用户赠送的任何产品或者服务。<br>
12.8 在任何情况下，划船星球均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用“划船星球”或本服务而遭受的利润损失，承担责任（即使划船星球已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，划船星球对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用划船星球提供的服务而支付给划船星球的费用(如有)。<br>
                            </span>
                            <p class="description text-start">
                                <strong>十三、服务的变更、中断、终止</strong>
                            </p>
                            <span class="description text-start">
                                13.1 鉴于网络服务的特殊性，用户同意划船星球有权随时变更、中断或终止部分或全部的服务（包括收费服务）。划船星球变更、中断或终止的服务，划船星球应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务。<br>
13.2 如发生下列任何一种情形，划船星球有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任：<br>
(1) 根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；<br>
(2) 用户违反相关法律法规或本协议的约定；<br>
(3) 按照法律规定或有权机关的要求；<br>
(4) 出于安全的原因或其他必要的情形。<br>
13.3 用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿划船星球与合作公司、关联公司，并使之免受损害。<br>
                            </span>
                            <p class="description text-start">
                                <strong>十四、其他</strong>
                            </p>
                            <span class="description text-start">
                                14.1 划船星球郑重提醒用户注意本协议中免除划船星球责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险<br>
14.2 本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和划船星球之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交划船星球住所地有管辖权的人民法院管辖。<br>
14.3 本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。<br>
14.4 由于互联网高速发展，您与划船星球签署的本协议列明的条款可能并不能完整罗列并覆盖您与划船星球所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，《划船星球隐私政策》等均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用划船星球平台服务，视为您同意上述补充协议。<br>
                            </span>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'

    export default {
        name: 'UserAgreement',
        components: {Layout},
        data() {
            return {
                
            }
        }
    }
</script>
