import Vue from 'vue'
import VueRouter from 'vue-router'
import ErrorPage from '../views/404'
import Roworld from '../views/home-pages/Roworld'
import RoworldEngilsh from '../views/home-pages/RoworldEnglish'
import UserAgreement from "../views/UserAgreement.vue"
import PrivacyPolicy from "../views/PrivacyPolicy.vue"
import AutomaticRenewal from "../views/AutomaticRenewal.vue"
import PaidMemberUserAgreement from "../views/PaidMemberUserAgreement.vue"
import About from "../views/About.vue"
import AboutRowing from "../views/AboutRowing"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Roworld',
        component: Roworld,
        meta: {
            title: 'ROWORLD划船星球 官方网站',
        },
    },
    {
        path: '/roworld-english',
        name: 'RoworldEngilsh',
        component: RoworldEngilsh,
        meta: {
            title: 'ROWORLD',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: '关于我们｜ROWORLD官网',
        },
    },
    {
        path: '/aboutRowing',
        name: 'About',
        component: AboutRowing,
        meta: {
            title: '关于赛艇｜ROWORLD官网',
        },
    },
    {
        path: '/userAgreement',
        name: 'UserAgreement',
        component: UserAgreement,
        meta: {
            title: '用户协议｜ROWORLD官网',
        },
    },
    {
        path: '/privacyPolicy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: {
            title: '隐私政策｜ROWORLD官网',
        },
    },
    {
        path: '/automaticRenewal',
        name: 'AutomaticRenewal',
        component: AutomaticRenewal,
        meta: {
            title: '自动续费条款｜ROWORLD官网',
        },
    },
    {
        path: '/paidMemberUserAgreement',
        name: 'PaidMemberUserAgreement',
        component: PaidMemberUserAgreement,
        meta: {
            title: '会员服务协议｜ROWORLD官网',
        },
    },
    {
        path: '/error',
        name: 'Error',
        component: ErrorPage,
        meta: {
            title: 'ROWORLD划船星球 官方网站',
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0);
    if (to.path) {
        if (window._hmt) {
             window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
        }
    }
    next();
});

export default router
