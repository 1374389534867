<template>
    <Layout header-class="header-transparent">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 height-900 bg_image div21">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner pt--80 text-center">
                            <h1 class="title display-one">
                                用划船连接世界
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-3 height-585" style="background-color: #FFFFFF">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner">
                            <span class="subtitle text-start">
                                一切从划船开始
                            </span>
                            <span class="description text-start">
                                “划船机是所有健身项目中燃脂效率最高的运动之一，但目前市面上大部分划船机和其他健身设备普遍缺少现代新技术的应用，以及对用户需求的深度挖掘。划船星球创立的初衷是要为用户打造一款高颜值、高科技、高智能化的全新家庭健身设备，全面提升用户体验，让健身从此不再枯燥乏味，而成为一段充满乐趣、成就感和参与感的挥汗时光”。
                            </span>
                            <br/>
                            <br/>
                            <br/>
                            <p class="description text-end" style="font-weight: 600; line-height: 40px;">
                                ——ROWORLD创始人兼CEO 张鹏
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-3 height-530 bg_image div23">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner">
                            <span class="subtitle text-start" style="color: #FFFFFF;">
                                Mission 使命
                            </span>
                            <span class="description text-start" style="color: #FFFFFF;">
                                通过对创新科学技术和算法的不断研发和应用，打造全新一代数字化智能划船机和其他健身系统，帮助健身爱好者们培养运动习惯、提升锻炼效率、享受运动过程。
                            </span>
                            <br/>
                            <br/>
                            
                            <span class="subtitle text-start" style="color: #FFFFFF;">
                                Vision 愿景
                            </span>
                            <span class="description text-start" style="color: #FFFFFF;">
                                成长为全世界顶尖的数字化智能健身生态品牌
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-3 height-585" style="background-color: #FFFFFF">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner">
                            <span class="subtitle text-start">
                                关于企业
                            </span>
                            <span class="description text-start">
                                划船星球成立于2020年，致力于中国本土高端智能健身设备和系统的研发及制造。运用前沿的硬件控制技术，AI智能算法和先进的运动生命科学理论打造新一代智能健身设备。目前公司已经获得知名投资机构和行业头部企业的多轮融资。
                                <br/>
                                <br/>
                                公司研发人员占比76%，拥有多项专利技术，核心团队由来自宝马中国、蔚来汽车、海信、百度、中国日报等知名企业的产品、研发、算法技术和互联网专家组成。
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <div class="height-442 bg-overlay bg_image div2f">

        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'

    export default {
        name: 'About',
        components: {Layout},
        data() {
            return {
                
            }
        }
    }
</script>

<style>
    @media only screen and (max-width: 991px) {
          .div21{
            background-image: url(../assets/images/bg/bg-sm-21.jpg);
          }
          .div23{
            background-image: url(../assets/images/bg/bg-sm-23.jpg);
          }
          .div2f{
            background-image: url(../assets/images/bg/bg-sm-2ft.jpg);
          }
        }
        @media only screen and (min-width: 991px) {
          .div21{
            background-image: url(../assets/images/bg/bg-lg-21.jpg);
          }
          .div23{
            background-image: url(../assets/images/bg/bg-lg-23.jpg);
          }
          .div2f{
            background-image: url(../assets/images/bg/bg-lg-2ft.jpg);
          }
        }
</style>