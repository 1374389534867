<template>
    <Layout header-class="header-transparent">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 height-900 bg_image div31">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner pt--80 text-center">
                            <h1 class="title display-one">
                                百舸争流 奋楫者先
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-3 height-1100" style="background-color: #FFFFFF">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner">
                            <span class="subtitle text-start">
                                划船机
                            </span>
                            <span class="description text-start" style="line-height: 1.7;">
                                划船机发明于19世纪80年代，是赛艇运动在陆地上的延续，也是赛艇选手训练体能，耐力和技术动作的重要科目。划船机的训练数据不仅可以监测运动员能力水平的变化，也可以预测其在真实水面上可能取得的成绩提升，因此划船机也被赛艇专业人士称之为测工仪。使用划船机锻炼可以调动全身85%的肌肉参与，同时坐姿运动有效减少对关节的冲击和损伤。
                                <br/>
                                <br/>
                                ROWORLD通过对大量真实水面划行数据进行生物力学分析，研究，自主开发出专利算法，结合先进自研电控科技，为用户提供更接近真实水面的划行体验。
                            </span>
                            <br/>
                            <br/>
                            <br/>
                            <span class="subtitle text-start">
                                赛艇运动
                            </span>
                            <span class="description text-start" style="line-height: 1.7;">
                                诞生于英国泰晤士河畔的伊顿公学，赛艇运动早在1896年举行的第一届奥林匹克运动会上即被列为正式比赛项目。美国著名的常青藤（IvyLeague）高校联盟也是因其最早在北美联合举办赛艇比赛而得名并延续。赛艇运动本身优雅的气质，对运动员身体能力的极限挑战，以及对团队协作精神的侧重，使其成为一项极具社交属性，横跨各圈层的精品体育项目。
                                <br/>
                                <br/>
                                在中国，越来越多的已经人开始了解，关注并接触到赛艇运动和赛艇文化。我们希望通过推出ROWORLD智能划船机等一系列产品，为更多的赛艇和其他运动爱好者提供更安全，高效，智能和高端的运动体验及生活方式。
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'

    export default {
        name: 'About',
        components: {Layout},
        data() {
            return {
                
            }
        }
    }
</script>

<style>
    @media only screen and (max-width: 991px) {
          .div31{
            background-image: url(../assets/images/bg/bg-sm-31.jpg);
          }
        }
        @media only screen and (min-width: 991px) {
          .div31{
            background-image: url(../assets/images/bg/bg-lg-31.jpg);
          }
        }
</style>